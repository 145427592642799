@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .input-number::-webkit-inner-spin-button,
  .input-number::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
}
